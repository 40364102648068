import React, {useState} from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import {images} from "../../constants";
import {Heading} from "../../Components/";
import { Col, Container, Row } from 'react-bootstrap';

const NewsAndEvents = () => {
  const [index, setIndex] = React.useState(-1);
  const handleClick = React.useCallback(({ index: current }) => {
    setIndex(current);
  }, []);

  const slides = [
    {
      src: images.video1,
      width: 200,
      height: 100,
    },
  ]
  

  return (
    <div className='NewsAndEvents'>
      <div className='pageTitle'>
        <h4>News & Events</h4>
      </div>

          <Container>
      <div className='eventsContent my-5'>
        <Heading title={"Events"} class={"borderedHeading"}/>

        <div className='galleryContainer my-2'>
          <Row>
            <Col lg={4}>
              <video width="100%" controls>
              <source src={images.video1} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </div>
      </div>
          </Container>
      
    </div>
  )
}

export default NewsAndEvents
